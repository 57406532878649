import React, { useState, useRef, useEffect } from 'react'
// import logo from "https://res.cloudinary.com/midas-acadeny/image/upload/v1645600761/RCCG/Rccg_logo_wsnioo.png"
import "./home.css"
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import {TweenMax, Power3} from 'gsap';
import "./home.scss"
import Gallery from './Gallery';
import useLocoScroll from './useLocalScroll';
import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'
import { TimelineMax } from 'gsap/gsap-core';
import { Power4 } from 'gsap/all';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import { urlFor, client } from './client';


gsap.registerPlugin(ScrollTrigger);

function Home() {


    // useLocoScroll(true)
    const [errorMsg, setErrorMsg] = useState('');
    const [submitting, setsubmitting] = useState(false);


   
    
    let testAni = useRef(null)
    let text = useRef(null)
    let line = useRef(null)
    let theBox = useRef(null)
    let pic2 = useRef(null)


    useEffect(() => {
    
    

    gsap.from(
        testAni,
        
        {
            scrollTrigger: {
                trigger: ".sticky",
                toggleActions: "restart pause reverse pause",
                // markers: true,
                start: "top 70%",
                end: "top 40%",
                scrub: 2,
            },
            x: 300,
            // scale: 0,
            opacity: 0,
            // ease: Power4.easeInOut,
            duration: 3
        }
    )

    gsap.from(
        theBox,
        
        {
            scrollTrigger: {
                trigger: ".sticky",
                toggleActions: "restart pause reverse pause",
                // markers: true,
                start: "top 70%",
                end: "top 35%",
                scrub: 2,
            },
            x: -300,
            y: -200,
            // scale: 0,
            opacity: 0,
            // ease: Power4.easeInOut,
            duration: 3
        }
    )

    gsap.from(
        text,
        
        {
            scrollTrigger: {
                trigger: ".text",
                toggleActions: "restart pause reverse pause",
                // markers: true,
                start: "top 70%",
                end: "top 35%",
                scrub: 2,
            },
            x: -300,
            // scale: 4,
            opacity: 0,
            // ease: Power4.easeInOut,
            duration: 3
        }
    )

    gsap.from(
        line,
        
        {
            scrollTrigger: {
                trigger: ".text",
                toggleActions: "restart pause reverse pause",
                // markers: true,
                start: "top 80%",
                end: "top 35%",
                scrub: 2,
            },
            // x: -300,
            // scale: 4,
            width: "10%",
            // ease: Power4.easeInOut,
            duration: 3
        }
    )

    gsap.from(
        pic2,
        
        {
            scrollTrigger: {
                trigger: ".sticky",
                toggleActions: "restart pause reverse pause",
                // markers: true,
                 start: "top 70%",
                end: "top 35%",
                scrub: 2,
            },
            // x: -300,
            // scale: 4,
            x: -300,
            scale: 0,
            opacity: 0,
        }
    )
    
      
    }, [])
    const [checked, setChecked] = useState(false)
    const checking = () =>{
        if(checked === false){
            setChecked(true)
        }else{
            setChecked(false)
            
        }
    }
    const [child, setChild] = useState(false)

    const childing = () =>{

        if(child === false){
            setChild(true)
        }else{
            setChild(false)
            
        }
    }

    const [headerBG, setHeaderBG] = useState()
    const [parishes, setParishes] = useState([])
    const [fContact, setFContact] = useState()
    const [gallary, setGallary] = useState()
    const [emailMessage, setEmailMessage] = useState()

    useEffect(() => {
        const query = '*[_type == "headerBG"][0]';
        const allParishes = '*[_type == "parishes"]';
        const contact = '*[_type == "contact"][0]';
        const gallary = '*[_type == "galary"][0]';
        const emailM = '*[_type == "emailMessage"][0]';
    
        client.fetch(query)
        .then((data) => setHeaderBG(data))

        client.fetch(allParishes)
        .then((data) => setParishes(data))

        client.fetch(contact)
        .then((data) => setFContact(data))

        client.fetch(gallary)
        .then((data) => setGallary(data))

        client.fetch(emailM)
        .then((data) => setEmailMessage(data))
        
    }, [])
    // console.log(emailMessage)
    // const bg = headerBG[0]
    // console.log(parishes)
    
   const [preloader, setPreloader] = useState(true)

   const [dayDate, setDayDate] = useState('')
    const [monthDate, setMonthDate] = useState('')
    const [yearDate, setYearDate] = useState('')
    const [dayDateAni, setDayDateAni] = useState('')
    const [monthDateAni, setMonthDateAni] = useState('')
    const [yearDateAni, setYearDateAni] = useState('')
    const [postcode, setPostcode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    // console.log(`daydate: ${dayDate}`)

    const dayChange = (e) => {
        let num = e.target.value
        if(num < 32){

            setDayDate(num)
        }
    } 
    const monthChange = (e) => {
        let num = e.target.value
        if(num < 13){

            setMonthDate(num)
        }
    } 
    const yearChange = (e) => {
        let num = e.target.value
        if(num < 10000){

            setYearDate(num)
        }
    }

    const phoneNumberChange = (e) => {
        let num = e.target.value
        if(num < 10000000000){

            setPhoneNumber(num)
        }
    }

    const dayChangeAni = (e) => {
        let num = e.target.value
        if(num < 32){

            setDayDateAni(num)
        }
    } 
    const monthChangeAni = (e) => {
        let num = e.target.value
        if(num < 13){

            setMonthDateAni(num)
        }
    } 
    const yearChangeAni = (e) => {
        let num = e.target.value
        if(num < 10000){

            setYearDateAni(num)
        }
    }

    const postcodeChange = (e) => {
        let num = e.target.value
        if(num < 10000){

            setPostcode(num)
        }
    }

    


 const submit = (event) => {
        event.preventDefault()
        const formElement = document.getElementById('registration-form');
        
        const form = new FormData(formElement)


        const pNumber = document.getElementById('phone-number').value
        const email = document.getElementById('email').value
        const cCode = document.getElementById('cCode').value
        const dob = `${dayDate}/${monthDate}/${yearDate}`
        const aniDate = `${dayDateAni}/${monthDateAni}/${yearDateAni}`
        if(!cCode){
            setErrorMsg("Choose a country code")
            return
        }

        if(!dayDate || !monthDate || !yearDate){
            setErrorMsg("Choose a date of birth")
            return
        }

        if(checked){
            if(!dayDateAni || !monthDateAni || !yearDateAni){
                setErrorMsg("Choose a date of Anniversary")
                return
            }
        form.append('anniversary', aniDate);

        }

        if(!child){
            if(!phoneNumber){
            setErrorMsg("Add a Phone number")
                return
            }

            if(!email){
            setErrorMsg("Add an email")
                return
            }
            
            // form.append('phone_number', `+${cCode}${pNumber}`);
            form.set('phone_number', `+${cCode}${pNumber}`);
        }

        if(child){

            if(!phoneNumber){

                form.append('phone_number', `A child`);
            }else{
            // form.append('phone_number', `+${cCode}${pNumber}`);

            }

            if(!email){

                form.append('email', `A child`);
            }
        }
        if(checked && child){
            setErrorMsg("A child can't be married 😏")
            return
        }
        form.append('is_married', checked);
            form.append('is_child', child);
        form.append('date_of_birth', dob);

        if(emailMessage){
        form.append('greeting', emailMessage.greeting);
        form.append('admonishment', emailMessage.admonishment);
        form.append('signing_out', emailMessage.signingOut);
        form.append('church_email', fContact.email);
        form.append('church_number', fContact.phoneNumder);
        form.append('church_address', fContact.address);

        // console.log(`email: ${emailMessage.admonishment}`)
       

        }else{
            setErrorMsg('No Network conection')
            return
        }

        
        setsubmitting(true);
        setErrorMsg('')
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/register`,
            {
                body: form,
                method: "post",
            }).then((res) => res.json()).then(res => {
                if(res.errors){
                    setErrorMsg(res.errors[0].message)
                }
                console.log(res)
                if(res.success){
            document.getElementById('first-name').value=''
            document.getElementById('last-name').value=''
            document.getElementById('phone-number').value=''
            document.getElementById('email').value=''
            document.getElementById('address').value=''
            setDayDate('')
            setMonthDate('')
            setYearDate('')
            setDayDateAni('')
            setMonthDateAni('')
            setYearDateAni('')
            setPostcode('')
            setPhoneNumber('')
            setChecked(false)
            setChild(false)
            document.getElementById('fast2').checked= false
            document.getElementById('fast').checked= false
            setErrorMsg(res.message)

            // document.getElementById('dp-image').value=''
            // document.getElementById('ministry').value=''
                }
                setsubmitting(false)
            }).catch(err => {
                console.log(err)
                setsubmitting(false)

                // console.log('e enter oo')
            })
    }

    

  
  return (
    <div className="home" >
        <div className="header" style={{backgroundImage: `url(${headerBG? urlFor(headerBG.hB) : "none"})`}} data-scroll-section>
            <div className="header_tab">
               
                <img src={process.env.PUBLIC_URL + '/Rccg_logo.png'} alt="RCCG_LOGO" />
            </div>
            <div className="header_content">

                <h1 className="header_heading">Welcome to <strong>Jesus Worship Center</strong></h1>
                <h3 className="header_sub_heading">Are you a first time guest? Register here!</h3>
                <form id="registration-form">
                
                    <div className="form_row">

                        <div className="form-control">
                            <input id="first-name" name="first_name" type="text" placeholder="Name" required/>
                            {/* <label>First Name<span>*</span> </label> */}
                        </div>
                        
                        <div className="form-control">
                            <input id="last-name" name="last_name" type="text" placeholder="Last Name" required/>
                            {/* <label>Last Name<span>*</span></label> */}
                        </div>
                    </div>
                    <div className="form_row">

                        
                        <div className="form-control">
                            <input id="email" name="email" type="email" placeholder="E-mail" required/>
                            {/* <label>Last Name<span>*</span></label> */}
                        </div>
                    <div className="form-control dob">
                        
                            <input value={dayDate} onChange={dayChange} className="day-date"  type="number" placeholder="dd" required/><span>/</span>
                            <input value={monthDate} onChange={monthChange} className="month-date"  type="number" placeholder="mm" required/><span>/</span>
                            <input value={yearDate} onChange={yearChange} className="year-date"  type="number" placeholder="yyyy" required/>
                            <label className="the-label">Date of Birth<span>*</span></label>
                        </div>
                    </div>

                        <div className="form-control num">
                        <select name="countryCode" id="cCode">
		<option data-countryCode="AU" value="61">Australia (+61)</option>
	<option data-countryCode="GB" value="44" Selected>UK (+44)</option>
	<option data-countryCode="US" value="1">USA (+1)</option>
    <optgroup label="Other countries">
		<option data-countryCode="DZ" value="213">Algeria (+213)</option>
		<option data-countryCode="AD" value="376">Andorra (+376)</option>
		<option data-countryCode="AO" value="244">Angola (+244)</option>
		<option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
		<option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
		<option data-countryCode="AR" value="54">Argentina (+54)</option>
		<option data-countryCode="AM" value="374">Armenia (+374)</option>
		<option data-countryCode="AW" value="297">Aruba (+297)</option>
		<option data-countryCode="AT" value="43">Austria (+43)</option>
		<option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
		<option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
		<option data-countryCode="BH" value="973">Bahrain (+973)</option>
		<option data-countryCode="BD" value="880">Bangladesh (+880)</option>
		<option data-countryCode="BB" value="1246">Barbados (+1246)</option>
		<option data-countryCode="BY" value="375">Belarus (+375)</option>
		<option data-countryCode="BE" value="32">Belgium (+32)</option>
		<option data-countryCode="BZ" value="501">Belize (+501)</option>
		<option data-countryCode="BJ" value="229">Benin (+229)</option>
		<option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
		<option data-countryCode="BT" value="975">Bhutan (+975)</option>
		<option data-countryCode="BO" value="591">Bolivia (+591)</option>
		<option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
		<option data-countryCode="BW" value="267">Botswana (+267)</option>
		<option data-countryCode="BR" value="55">Brazil (+55)</option>
		<option data-countryCode="BN" value="673">Brunei (+673)</option>
		<option data-countryCode="BG" value="359">Bulgaria (+359)</option>
		<option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
		<option data-countryCode="BI" value="257">Burundi (+257)</option>
		<option data-countryCode="KH" value="855">Cambodia (+855)</option>
		<option data-countryCode="CM" value="237">Cameroon (+237)</option>
		<option data-countryCode="CA" value="1">Canada (+1)</option>
		<option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
		<option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
		<option data-countryCode="CF" value="236">Central African Republic (+236)</option>
		<option data-countryCode="CL" value="56">Chile (+56)</option>
		<option data-countryCode="CN" value="86">China (+86)</option>
		<option data-countryCode="CO" value="57">Colombia (+57)</option>
		<option data-countryCode="KM" value="269">Comoros (+269)</option>
		<option data-countryCode="CG" value="242">Congo (+242)</option>
		<option data-countryCode="CK" value="682">Cook Islands (+682)</option>
		<option data-countryCode="CR" value="506">Costa Rica (+506)</option>
		<option data-countryCode="HR" value="385">Croatia (+385)</option>
		<option data-countryCode="CU" value="53">Cuba (+53)</option>
		<option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
		<option data-countryCode="CY" value="357">Cyprus South (+357)</option>
		<option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
		<option data-countryCode="DK" value="45">Denmark (+45)</option>
		<option data-countryCode="DJ" value="253">Djibouti (+253)</option>
		<option data-countryCode="DM" value="1809">Dominica (+1809)</option>
		<option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
		<option data-countryCode="EC" value="593">Ecuador (+593)</option>
		<option data-countryCode="EG" value="20">Egypt (+20)</option>
		<option data-countryCode="SV" value="503">El Salvador (+503)</option>
		<option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
		<option data-countryCode="ER" value="291">Eritrea (+291)</option>
		<option data-countryCode="EE" value="372">Estonia (+372)</option>
		<option data-countryCode="ET" value="251">Ethiopia (+251)</option>
		<option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
		<option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
		<option data-countryCode="FJ" value="679">Fiji (+679)</option>
		<option data-countryCode="FI" value="358">Finland (+358)</option>
		<option data-countryCode="FR" value="33">France (+33)</option>
		<option data-countryCode="GF" value="594">French Guiana (+594)</option>
		<option data-countryCode="PF" value="689">French Polynesia (+689)</option>
		<option data-countryCode="GA" value="241">Gabon (+241)</option>
		<option data-countryCode="GM" value="220">Gambia (+220)</option>
		<option data-countryCode="GE" value="7880">Georgia (+7880)</option>
		<option data-countryCode="DE" value="49">Germany (+49)</option>
		<option data-countryCode="GH" value="233">Ghana (+233)</option>
		<option data-countryCode="GI" value="350">Gibraltar (+350)</option>
		<option data-countryCode="GR" value="30">Greece (+30)</option>
		<option data-countryCode="GL" value="299">Greenland (+299)</option>
		<option data-countryCode="GD" value="1473">Grenada (+1473)</option>
		<option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
		<option data-countryCode="GU" value="671">Guam (+671)</option>
		<option data-countryCode="GT" value="502">Guatemala (+502)</option>
		<option data-countryCode="GN" value="224">Guinea (+224)</option>
		<option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
		<option data-countryCode="GY" value="592">Guyana (+592)</option>
		<option data-countryCode="HT" value="509">Haiti (+509)</option>
		<option data-countryCode="HN" value="504">Honduras (+504)</option>
		<option data-countryCode="HK" value="852">Hong Kong (+852)</option>
		<option data-countryCode="HU" value="36">Hungary (+36)</option>
		<option data-countryCode="IS" value="354">Iceland (+354)</option>
		<option data-countryCode="IN" value="91">India (+91)</option>
		<option data-countryCode="ID" value="62">Indonesia (+62)</option>
		<option data-countryCode="IR" value="98">Iran (+98)</option>
		<option data-countryCode="IQ" value="964">Iraq (+964)</option>
		<option data-countryCode="IE" value="353">Ireland (+353)</option>
		<option data-countryCode="IL" value="972">Israel (+972)</option>
		<option data-countryCode="IT" value="39">Italy (+39)</option>
		<option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
		<option data-countryCode="JP" value="81">Japan (+81)</option>
		<option data-countryCode="JO" value="962">Jordan (+962)</option>
		<option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
		<option data-countryCode="KE" value="254">Kenya (+254)</option>
		<option data-countryCode="KI" value="686">Kiribati (+686)</option>
		<option data-countryCode="KP" value="850">Korea North (+850)</option>
		<option data-countryCode="KR" value="82">Korea South (+82)</option>
		<option data-countryCode="KW" value="965">Kuwait (+965)</option>
		<option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
		<option data-countryCode="LA" value="856">Laos (+856)</option>
		<option data-countryCode="LV" value="371">Latvia (+371)</option>
		<option data-countryCode="LB" value="961">Lebanon (+961)</option>
		<option data-countryCode="LS" value="266">Lesotho (+266)</option>
		<option data-countryCode="LR" value="231">Liberia (+231)</option>
		<option data-countryCode="LY" value="218">Libya (+218)</option>
		<option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
		<option data-countryCode="LT" value="370">Lithuania (+370)</option>
		<option data-countryCode="LU" value="352">Luxembourg (+352)</option>
		<option data-countryCode="MO" value="853">Macao (+853)</option>
		<option data-countryCode="MK" value="389">Macedonia (+389)</option>
		<option data-countryCode="MG" value="261">Madagascar (+261)</option>
		<option data-countryCode="MW" value="265">Malawi (+265)</option>
		<option data-countryCode="MY" value="60">Malaysia (+60)</option>
		<option data-countryCode="MV" value="960">Maldives (+960)</option>
		<option data-countryCode="ML" value="223">Mali (+223)</option>
		<option data-countryCode="MT" value="356">Malta (+356)</option>
		<option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
		<option data-countryCode="MQ" value="596">Martinique (+596)</option>
		<option data-countryCode="MR" value="222">Mauritania (+222)</option>
		<option data-countryCode="YT" value="269">Mayotte (+269)</option>
		<option data-countryCode="MX" value="52">Mexico (+52)</option>
		<option data-countryCode="FM" value="691">Micronesia (+691)</option>
		<option data-countryCode="MD" value="373">Moldova (+373)</option>
		<option data-countryCode="MC" value="377">Monaco (+377)</option>
		<option data-countryCode="MN" value="976">Mongolia (+976)</option>
		<option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
		<option data-countryCode="MA" value="212">Morocco (+212)</option>
		<option data-countryCode="MZ" value="258">Mozambique (+258)</option>
		<option data-countryCode="MN" value="95">Myanmar (+95)</option>
		<option data-countryCode="NA" value="264">Namibia (+264)</option>
		<option data-countryCode="NR" value="674">Nauru (+674)</option>
		<option data-countryCode="NP" value="977">Nepal (+977)</option>
		<option data-countryCode="NL" value="31">Netherlands (+31)</option>
		<option data-countryCode="NC" value="687">New Caledonia (+687)</option>
		<option data-countryCode="NZ" value="64">New Zealand (+64)</option>
		<option data-countryCode="NI" value="505">Nicaragua (+505)</option>
		<option data-countryCode="NE" value="227">Niger (+227)</option>
		<option data-countryCode="NG" value="234">Nigeria (+234)</option>
		<option data-countryCode="NU" value="683">Niue (+683)</option>
		<option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
		<option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
		<option data-countryCode="NO" value="47">Norway (+47)</option>
		<option data-countryCode="OM" value="968">Oman (+968)</option>
		<option data-countryCode="PW" value="680">Palau (+680)</option>
		<option data-countryCode="PA" value="507">Panama (+507)</option>
		<option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
		<option data-countryCode="PY" value="595">Paraguay (+595)</option>
		<option data-countryCode="PE" value="51">Peru (+51)</option>
		<option data-countryCode="PH" value="63">Philippines (+63)</option>
		<option data-countryCode="PL" value="48">Poland (+48)</option>
		<option data-countryCode="PT" value="351">Portugal (+351)</option>
		<option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
		<option data-countryCode="QA" value="974">Qatar (+974)</option>
		<option data-countryCode="RE" value="262">Reunion (+262)</option>
		<option data-countryCode="RO" value="40">Romania (+40)</option>
		<option data-countryCode="RU" value="7">Russia (+7)</option>
		<option data-countryCode="RW" value="250">Rwanda (+250)</option>
		<option data-countryCode="SM" value="378">San Marino (+378)</option>
		<option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
		<option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
		<option data-countryCode="SN" value="221">Senegal (+221)</option>
		<option data-countryCode="CS" value="381">Serbia (+381)</option>
		<option data-countryCode="SC" value="248">Seychelles (+248)</option>
		<option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
		<option data-countryCode="SG" value="65">Singapore (+65)</option>
		<option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
		<option data-countryCode="SI" value="386">Slovenia (+386)</option>
		<option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
		<option data-countryCode="SO" value="252">Somalia (+252)</option>
		<option data-countryCode="ZA" value="27">South Africa (+27)</option>
		<option data-countryCode="ES" value="34">Spain (+34)</option>
		<option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
		<option data-countryCode="SH" value="290">St. Helena (+290)</option>
		<option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
		<option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
		<option data-countryCode="SD" value="249">Sudan (+249)</option>
		<option data-countryCode="SR" value="597">Suriname (+597)</option>
		<option data-countryCode="SZ" value="268">Swaziland (+268)</option>
		<option data-countryCode="SE" value="46">Sweden (+46)</option>
		<option data-countryCode="CH" value="41">Switzerland (+41)</option>
		<option data-countryCode="SI" value="963">Syria (+963)</option>
		<option data-countryCode="TW" value="886">Taiwan (+886)</option>
		<option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
		<option data-countryCode="TH" value="66">Thailand (+66)</option>
		<option data-countryCode="TG" value="228">Togo (+228)</option>
		<option data-countryCode="TO" value="676">Tonga (+676)</option>
		<option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
		<option data-countryCode="TN" value="216">Tunisia (+216)</option>
		<option data-countryCode="TR" value="90">Turkey (+90)</option>
		<option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
		<option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
		<option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
		<option data-countryCode="TV" value="688">Tuvalu (+688)</option>
		<option data-countryCode="UG" value="256">Uganda (+256)</option>
		<option data-countryCode="UA" value="380">Ukraine (+380)</option>
		<option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
		<option data-countryCode="UY" value="598">Uruguay (+598)</option>
		<option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
		<option data-countryCode="VU" value="678">Vanuatu (+678)</option>
		<option data-countryCode="VA" value="379">Vatican City (+379)</option>
		<option data-countryCode="VE" value="58">Venezuela (+58)</option>
		<option data-countryCode="VN" value="84">Vietnam (+84)</option>
		<option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
		<option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
		<option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
		<option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
		<option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
		<option data-countryCode="ZM" value="260">Zambia (+260)</option>
		<option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
	</optgroup>
    </select>
                            <input value={phoneNumber} onChange={phoneNumberChange} id="phone-number" name="phone_number" type="number"  placeholder="Phone Number" required/>
                            {/* <label>First Name<span>*</span> </label> */}
                        </div>

                        <div className="form_row">

                        
                            <div className="form-control address_state">
                                <input id="address" name="address" type="text" placeholder="Address" required/>
                                <select className="stateCode" id="stateCode" name="state_code" >
	<option data-countryCode="GB" value="QLD" Selected>QLD</option>
		<option data-countryCode="SA" value="SA">SA</option>
		<option data-countryCode="TAS" value="TAS">TAS</option>
		<option data-countryCode="VIC" value="VIC">VIC</option>
		<option data-countryCode="WA" value="WA">WA</option>
		<option data-countryCode="ACT" value="ACT">ACT</option>
		<option data-countryCode="NT" value="NT">NT</option>
		<option data-countryCode="NT" value="NT">NSW</option>
    </select>
    <input value={postcode} onChange={postcodeChange}  name="postcode" id="postcode" className="postcode"  type="number" placeholder="Postcode" required/>

                                {/* <label>Last Name<span>*</span></label> */}
                            </div>
                            <div className="gender">
                            <input type="radio" name="gender" value="male" id="male"/> Male
                            <input type="radio" name="gender" value="female" id="female" /> Female

                            </div>
                        </div>

                        <div className="form_row">

                        <div className="form-control2">
                        <div class="toggle-container">
                                <span>Are you Married?</span>
                            <input type="checkbox" class="toggle" id="fast" onChange={checking}/>
                                <label for="fast" class="labeled">
                                    <div class="ball">{`${checked? "YES":"NO"}`}</div>
                                </label>
                            </div>
                    </div>
                    

                        
                        <div className={`form-control ani ${checked? "hide": "anniversary"}`}>
                        <input value={dayDateAni} onChange={dayChangeAni} className="day-date"  type="number" placeholder="dd" required/><span>/</span>
                            <input value={monthDateAni} onChange={monthChangeAni} className="month-date"  type="number" placeholder="mm" required/><span>/</span>
                            <input value={yearDateAni} onChange={yearChangeAni} className="year-date"  type="number" placeholder="yyyy" required/>
                            {/* <input id="anniversary" name="anniversary" type="date" placeholder="Last Name" required/> */}
                            <label>Wed Anniversary<span>*</span></label>
                        </div>
                    </div>
                    <div class="toggle-container">
                                <span>A Child?</span>
                            <input type="checkbox" class="toggle2" id="fast2" onChange={childing}/>
                                <label for="fast2" class="labeled2">
                                    <div class="ball2">{`${child? "YES":"NO"}`}</div>
                                </label>
                            </div>
                    <p className="error-message">{errorMsg}</p>
                <button onClick={submit} className="register-btn">{submitting ? 'REGISTERING...' : 'REGISTER NOW'}</button>

                    
               </form>
            </div>
        </div>

        <div className="about_rccg" data-scroll-section>
            <div className="boxes"  id="boxes">
                <div className="box mission" >
                    {/* target image goes here */}
                    <ModeStandbyIcon fontSize="large" className="box_icon"/>
                    <h3>VISION/MISSION</h3>
                    <p>As received by the General Overseer, Pastor E.A. Adeboye, and communicated to the leaders, our vision and mission statement shall read:</p>
                    
                    <div className="fade_box"><span>Read More &#9759;</span></div>
                    <ul>
                        <li>To make heaven</li>
                        <li>To take as many people with us</li>
                        <li>To have a member of RCCG in every family of all nations.</li>
                        <li>To accomplish No. 1 above, holiness will be our lifestyle.</li>
                        <li>To accomplish No. 2 and 3 above, we will plant churches within five minutes walking distance and within five minutes driving distance in every city and town of developed countries.</li>
                    </ul>
                        <p>We will pursue these objectives until every nation in the world is reached for the Lord Jesus Christ.</p>
                </div>
                <div className="box parish" >
                    {/* target image goes here */}
                    <CelebrationOutlinedIcon fontSize="large" className="box_icon"/>
                    <h3>RCCG Queensland Parishes</h3>
                    <ul>
                    {parishes?.map((parish, index) => (
                        
                        <li key={index}><AddLocationIcon className="location_icon" /><a href={parish.parishUrl} >{`${parish.parishName}. ${parish.parishLocation}`}</a></li>
                    ))}
                    </ul>
                </div>
                <div className="box programme" > 
                    {/* target image goes here */}
                    <AccountBalanceOutlinedIcon fontSize="large" className="box_icon"/>
                    <h3>Our programmes</h3>
                    <ul>
                        <li>Sunday Service <span className="programme_time">(10am – 12pm)</span></li> 
                        <li>Bible Study <span className="programme_time">(Wednesday 6:30 pm – 7:30 pm)</span> </li>
                        <li>Faith Clinic <span className="programme_time">(Friday 6:30 pm – 7:30 pm ) </span> <italic className="programme_time">Every First Wednesday of the month.</italic> </li>
                        <li>Last Friday Vigil <span className="programme_time"> (10 pm – 1 am) </span> <italic className="programme_time"> Every last Friday of the month.</italic> </li>
                        <li>House Fellowship <span className="programme_time">(Sunday 5:30 pm – 6:30 pm)</span> <italic className="programme_time"> Any Fellowship Centre Nearest to You.</italic></li>
                    </ul>
                    <div className="fade_box"><span>Read More&#9759;</span></div>
                </div>
            </div>
            <div className="about_content" >
                <p>Welcome to Jesus Worship Centre. A place of power, worship and Praise. A place where God love reigns, Champions are raised, and where were dreams come true. </p><p> 

Jesus Worship Centre is home of worship, an abode to worship God’s infinite mercy with believers. We believe our journey begins with fellowshipping
together in God’s presence. You are most welcome</p>
            </div>
        </div>
       {/* <Gallery /> */}
       <section class="sticky"  data-scroll-section>
  <blockquote className="text" ref={el => {text = el}}>"Worship with us at RCCG <strong>Jesus Worship Center</strong> every sunday at 10am"<span ref={el => {line = el}} ></span></blockquote>
  <img id="office" ref={el => {testAni = el}} src={gallary? urlFor(gallary.Img) : "No Internet"} />
  <img id="building" ref={el => {pic2 = el}} src={gallary? urlFor(gallary.Img2) : "No Internet"} />
  <div id="box" ref={el => {theBox = el}}></div>
</section>

        <footer className="footer" >
            <div className="footer_top">
                <div className="footer_top-logo">
                    <img src={process.env.PUBLIC_URL + '/Rccg_logo.png'} alt="logos" />
                    <div className="footer_top-address">
                    <BusinessIcon fontSize="small" className="b-icon" />
                    <p className="p-text">{fContact?.address}</p>
                    </div>
                </div>
                <div className="footer_top-get-in-touch">
                    <h4 className="f-head">Get In Touch</h4>
                    <div className="footer_top-tel">
                        <PhoneIcon fontSize="small" className="b-icon"/>
                        <a href={`tel:${fContact?.phoneNumber}`} className="p-text">{fContact?.phoneNumder}</a>

                    </div>
                    <div className="footer_top-email">
                        <EmailIcon fontSize="small" className="b-icon"/>
                        <a href={`${fContact?.email}`} className="p-text">{fContact?.email}</a>
                    </div>
                </div>
                <div className="footer_top-quick-link">
                    <h4 className="f-head">Quick Links</h4>
                    <a href="#registration-form" className="p-text">Register</a>
                    <a href="#boxes" className="p-text">Vision</a>
                    <a href="#boxes" className="p-text">Parishes</a>

                </div>
            </div>
            <div className="footer_bottom">
                <div className="footer_bottom-copyright">
                    <p className="p-text"> © RCCG JWC | All Rights Reserved.</p>
                </div>
                <div className="footer_bottom-socials socials">

        <a href="https://www.facebook.com/RCCGJesusWorshipCentre/?ti=as" target="_blank">

            <FacebookOutlinedIcon fontSize="large" className="facebook_icon"/>
            </a>
            <a href="https://www.instagram.com/rccgjesusworshipcentre/" target="_blank">

            <InstagramIcon fontSize="large" className="facebook_icon"/>
            </a>
            <a href="https://www.youtube.com/channel/UCg1lwiM7LQgp5uGT7nicAQQ" target="_blank">

            <YouTubeIcon fontSize="large" className="facebook_icon"/>
            </a>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Home