import React, { useContext, useEffect, useState } from 'react'
import {auth} from './config/firebase-config'
// import createStore from 'react-auth-kit/createStore';

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

// export const store = createStore({
//     authName:'_auth',
//     authType:'cookie',
//     cookieDomain: window.location.hostname,
//     cookieSecure: window.location.protocol === 'https:',
//   });


// export function AuthProvider({ children }) {
//     const [currentUser, setCurrentUser] = useState()
//     const [loading, setLoading] = useState(true)

    
//     function signup( email, password){
//         return auth.createUserWithEmailAndPassword(email, password)
//     }
    
//     function login( email, password){
//         try {
//             const response = await fetch("your-api-endpoint/auth/login", {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//               },
//               body: JSON.stringify(data),
//             });
//             const res = await response.json();
//             if (res.data) {
//               setUser(res.data.user);
//               setToken(res.token);
//               localStorage.setItem("site", res.token);
//               navigate("/dashboard");
//               return;
//             }
//             throw new Error(res.message);
//           } catch (err) {
//             console.error(err);
//           }
//     }

//     function logout(){
//         return auth.signOut()
//     }

//     useEffect(() => {
//         const unsubscribe = auth.onAuthStateChanged(user => {
//             setCurrentUser(user)
//             setLoading(false)
//         })
//         // console.log(auth.getUser("h1qxx56xd9RwfPbfRFQWCc750c72"))
//         return unsubscribe
//     }, [])
//     const value = {
//         currentUser,
//         signup,
//         login,
//         logout
//     }
//   return (
//     <AuthContext.Provider value={value}>
//         {!loading && children}
//     </AuthContext.Provider>
//   )
// }
