import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './loginPage.scss'
import axios from 'axios';

import {useSignIn} from "react-auth-kit";
// import { useAuth } from './AuthContext'
import { Password } from '@mui/icons-material';

function LoginPage() {

    const emailRef = useRef()
    const passwordRef = useRef()
    // const { login } = useAuth()
    const [error, setError] = useState('')
    const [successMessage, setSuccesssMessage] = useState('')
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const signIn = useSignIn();


     const handleSubmit  = async (e) => {
        e.preventDefault()

        const values = {
            email: emailRef.current?.value,
            password: passwordRef.current?.value,
        }

            setSuccesssMessage("")
            setLoading(true)
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login`,
                values
              ).then((response) => {
        console.log(response.data, "---response")
               signIn({
                token: response.data.token.token,
                expiresIn: 3600,
                tokenType: "Bearer",
                authState: response.data.user,
            });
            setError('')

            setSuccesssMessage("successfully Signed in")
            navigate("/b-end")
        }).catch((error) => {
            console.log(error, "---error")
            setError(error)
            setLoading(false)
        }
        )

    }

   
  return (
    <div className="loginPage">
        <div className="container">
        <h2>LOGIN</h2>
        <form className="login_form">

            <div className="email_password_input">
                <input className="login_input" type="email" id="email-login" ref={emailRef} required/>
                <label htmlFor="email">E-mail</label>
            </div>
            <div className="email_password_input">
                <input className="login_input" type="password" id="password-login" ref={passwordRef} required/>
                <label htmlFor="password">Password</label>
            </div>
            <p className="error-message-l">{error}</p>
            <p className="success-message-l">{successMessage}</p>
            <button onClick={handleSubmit} className="login_btn">
                Login
            </button>
        </form>
        </div>
        <Link className="link_login" to='/'>
            <p className="bth">Back to home</p>
        </Link>
    </div>
  )
}

export default LoginPage