// import logo from './logo.svg';
import "./App.css";
import { Route, Routes } from "react-router";
import Home from "./Home";
import Dashboard from "./Dashboard";
import UserProfile from "./UserProfile";
import LoginPage from "./LoginPage";
import SignUp from "./SignUp";
import { BrowserRouter } from "react-router-dom";
import {AuthProvider, RequireAuth} from "react-auth-kit";

function App() {
  return (
    <AuthProvider 
    authName={"_auth"}
    authType={"cookie"}
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}
    >
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />

            <Route
          path="/b-end"
          element={
            <RequireAuth loginPath="/login">
              <Dashboard />
            </RequireAuth>
          }
        ></Route>
            
            <Route
          path="/b-end/user_profile/:user_id"
          element={
            <RequireAuth loginPath="/login">
              <UserProfile />
            </RequireAuth>
          }
        ></Route>
           
              <Route
          path="/signup"
          element={
            <RequireAuth loginPath="/login">
              <SignUp />
            </RequireAuth>
          }
        ></Route>
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
