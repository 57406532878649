import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './loginPage.scss'

import { useAuth } from './AuthContext'

function SignUp() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [successMessage, setSuccesssMessage] = useState('')
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();



     const handleSubmit  = async (e) => {
        e.preventDefault()

        if(passwordRef.current.value !== confirmPasswordRef.current.value){
            return setError('Passwords do not match')
        }

        try{
            setError('')
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            setSuccesssMessage("successfully added")
            navigate("/b-end")

        }catch {
            setError('Failed to create account')
            setLoading(false)
        }
    }

   
    
  return (
    <div className="signUp loginPage">
        <div className="container">
        <h2>SIGN UP</h2>
        <form  className="login_form">

            <div className="email_password_input">
                <input className="login_input" type="email" id="email-login" ref={emailRef} required/>
                <label className="ep_label" htmlFor="email">E-mail</label>
            </div>
            <div className="email_password_input">
                <input className="login_input" type="password" id="password-signup" ref={passwordRef} required/>
                <label htmlFor="password">Password</label>
            </div>
            <div className="email_password_input">
                <input className="login_input" type="password" id="password-signup-confirm" ref={confirmPasswordRef} required/>
                <label htmlFor="password">Cornfirm Password</label>
            </div>
            <p className="error-message-l">{error}</p>
            <p className="success-message-l">{successMessage}</p>

            <button disabled={loading} onClick={handleSubmit} className="login_btn">
                Add User
            </button>
        </form>
        </div>
        <Link className="link_login" to='/login'>
            <p className="bth">Log Out</p>
        </Link>
    </div>
  )
}

export default SignUp