import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './allUsers.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';

function AllUsers({data, triggerUserLenght})  {

    // console.log( data )

    // console.log(`the sent data: ${data[2].first_name}`)
    
    const [counter, setCounter] = useState(10);
    
    // if(counter > 2){
        //     setCounter(counter - 1)
        //     return counter
        // }
        const [users, setUsers] = useState([]);
        // setUsers(data)
        
        useEffect(()=>{
            setUsers(data)
            
            triggerUserLenght(data.length)
        }, [data])
        
        useEffect(()=>{
            
        }, [])
  
        document.addEventListener('DOMContentLoaded', ()=>{
            
            setCounter(counter + 1);
        });

        const userLenght = () => {

        }
    
    
    const id = setInterval(() => {

      }, 1000);

     clearInterval(id)


    function num() {

        
       
        return(
            counter
        )
    }


    const [clicked, setClicked] = useState(false)
    const openOption = () =>{
        if(clicked){
            setClicked(false)
        }else if(!clicked){
            setClicked(true)
        }
    }

    const deleteUser = (user_id) =>{
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/register/delete/${user_id}`).then(res =>{
                // console.log(res)
                // setUsers(res.data.users)
                setUsers(users.filter((user) => user.user_id !== user_id))
                
            })
           
            .catch(err=>{
                    console.log(err)
                })
                triggerUserLenght(users.length)
                // console.log(users.length)
            }

            // const updateUser = (user_id) =>{
            //     axios.delete(`${process.env.REACT_APP_BACKEND_URL}/updateuser/${user_id}`).then(res =>{
            //             // console.log(res)
            //             // setUsers(res.data.users)
            //             setUsers(users.filter((user) => user.user_id !== user_id))
            //         })
            //         .catch(err=>{
            //                 console.log(err)
            //             })
            //         }
    return (
        <div className="all-users">
            {/* <p>{num}</p> */}
            <ul className="table-heading">
                <li>Name</li>
                <li>Phone Number</li>
                <li>Date of Birth</li>
                <li>Wedding Anniversary</li>
                <li></li>

            </ul>
                {users.map(user =>(
            <ul className={`single_user ${clicked? "active": ""}`} onClick={openOption}>
                    {/* <li key={user.id}> {user.id}</li> */}
                    <li key={user.id}> {user.first_name}{" "}{user.last_name}</li>
                    <li key={user.id}> {user.phone_number}</li>
                    <li key={user.id}> {user.date_of_birth}</li>
                    <li key={user.id}> {user.anniversary? user.anniversary : 'Not Married'}</li>
                    <div className="user_checkbox">
                        <DeleteForeverOutlinedIcon onClick={()=>deleteUser(user.user_id)} fontSize="small" className="delete_user" />
                        <Link to={`/b-end/user_profile/${user.user_id}`}>
                        
                        {/* <EditOutlinedIcon  className="update_user" /> */}
                        </Link>
                    </div>
            </ul>
                ))}
               
        </div>
    )
}

export default AllUsers
