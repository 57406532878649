import axios from 'axios';
import React, { useEffect, useState } from 'react'
import AllUsers from './AllUsers'
import SearchIcon from '@mui/icons-material/Search';
import './dashboard.css'
import CloseIcon from '@mui/icons-material/Close';
import { ExportCSV } from './ExportCSV';
import { Link, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext'
import { useSignOut } from "react-auth-kit";
import Cookies from 'js-cookie';

// import useFetch from './useFetch';


function Dashboard() {

    const [auth, setAuth] = useState(true || window.localStorage.getItem('auth') === 'true')
    const [token, setToken] = useState('')
    const singOut = useSignOut();
    const navigate = useNavigate();
  
    const logout = () => {
      singOut();
      navigate("/login");
    };

    // useEffect(() => {
    
    //     firebase.auth().onAuthStateChanged((userCred) => {
    //         if(userCred){
    //             setAuth(true);
    //             window.localStorage.setItem('auth', 'true');
    //             userCred.getIdToken().then((token) => {
    //                 setToken(token)
    //             })
    //         }
    //     })
        
    // }, [])
    // const loginWithGoogle = () =>{
    //     firebase
    //     .auth()
    //     .signInWithPopup(new firebase.auth.GoogleAuthProvider())
    //     .then((userCred) =>{
    //         window.localStorage.setItem('auth', 'true');

    //         console.log(userCred)
    //         setAuth(true)
    //     })
    // }

    const [users, setUsers] = useState([]);
   
    
    // useEffect(() => {
        //     const testData = db.users;
        
        // }, [])
        
        // const httpsReference = ref(storage, 'https://invite-appy.herokuapp.com/registered_users');  
        
        
        // console.log("Storage: " + httpsReference)
        
        // const { data: datas, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/registered_users`)
        // // console.log(`res=>: ${datas.length}`)

        // const sata = JSON.stringify(datas);
        // console.log(`res=>: ${Object(datas)[0].email}`)

        useEffect(()=>{
            // console.log(process.env.REACT_APP_BACKEND_URL);

            async function fetchData(token) {

                const request = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/register`, {
                    withCredentials: true,
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('_auth')}`
                    }
                })
                setUsers(request.data.data);
               
                        }
            
        
                
                            fetchData()
                        
                    }, [])
                    

                        // console.log(`Res: ${users[0].first_name}`)
                    
                    
    // const ministers = users.filter(user => user.is_minister === true);
    // const delegates = users.filter(user => user.is_minister === false);
    // const [day, setDay] = useState("1");

    // function makeDay1() {
    //     setDay('1');
    // }

    const ministerList = document.getElementById('ministers-list');
    const delegateList = document.getElementById('delegates-list');
    // function openList() {
    //     ministerList.classList.add("active")
    // }

    

    // function openDelegateList() {
    //     delegateList.classList.add("active")
    // }

    
    // function makeDay2() {
    //     setDay('2');
    // }
    
    // function makeDay3() {
    //     setDay('2-evening');
    // }
    
    // function makeDay4() {
    //     setDay('4');
    // }
    
    // function makeDay5() {
    //     setDay('5');
    // }
    
    function closeList() {
        ministerList.classList.remove("active")
        delegateList.classList.remove("active")
    }
    useEffect(() => {
        
        
        
        const dButtons = document.querySelectorAll('.d-btn');
        
        dButtons.forEach(dButton =>{
            
            dButton.addEventListener('click', ()=>{
                removeActiveClasses();
                dButton.classList.add('active')
            })
            
        })
        
        function removeActiveClasses(){
            dButtons.forEach(dButton =>{
                dButton.classList.remove('active');
            });
        }
        
    }, [])

    const [query, setQuery] = useState("")
    // console.log(query)
    useEffect(() => {
        const search = document.querySelector('.search-bar');
        const btn = document.querySelector('.btn');
    const searchList = document.getElementById('search-list');
    const isearch = document.querySelector('.isearch');
        
        // btn.addEventListener('click', ()=>{
        // searchList.classList.toggle("active")
        // search.classList.toggle('active');
        //     isearch.focus()
        // });
    }, [])
    // const modUsers = users;

    const modMinisters = (user) =>user.filter( minister => {
        // delete minister.is_minister
        delete minister.profile_image
        delete minister.qrcodeurl
        delete minister.email
       
    });


    const [errorMsg, setErrorMsg] = useState('');
    const [submit, setsubmit] = useState(false);
    
    // useEffect(()=>{
           const deleteUser = (user_id) =>{
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete/${user_id}`).then(res =>{
                // console.log(res)
                // setUsers(res.data.users)
                setUsers(users.filter((user) => user.user_id !== user_id))
            })
            .catch(err=>{
                    console.log(err)
                })
            }
            // }, [])

    
  const hideqr =() =>{
    if(submit){
           
        setsubmit(false);
     }
  }
    
  const [people, setPeople] = useState([]);
  
  
//   let navigate = useNavigate();
  
  const updateUserLenght =(length) => {
      setPeople(length)
    }
    const [error, setError] = useState('')
    // const { logout, currentUser } = useAuth()

    const handleLogout = async () =>{
        setError('')
        navigate("/")

        try{
            // await logout()

        }catch{
            setError('failed to logout')
        }
    }
    return (
        <div className="dashboard">
           
           {auth ? (
                <div className="dashboard">

                    <header>
                        <div className="dashboard-after-header">
        
                        <div className="dashboard-heading">
        
                        <h1>Dashboard</h1>
                        <h3>Registration</h3>
                        </div>
                     
                        </div>
        
                    </header>
                    <div className="overview">
                        <div className="registered">
                            <h4>Registered</h4>
                            <h1>{people}{" "} People</h1>
                        </div>
                       
                    </div>
                    <div className="attendance">
                        <div class="search-bar">
                            <div class="btn-null" >
                                <p onClick={logout}>Log Out</p>

                            </div>
                                {/* <button onClick={() =>{navigate('/signup')}} className="download-sheet">Add Backend User</button> */}
                            <input type="text" placeholder="Search..." class="isearch" onChange={event => setQuery(event.target.value)} />
                        </div>
                        <div className="registered-users">
                            <AllUsers triggerUserLenght={updateUserLenght} data={ users.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.first_name.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }else if (post.last_name.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                    })}/>
        
                        </div>
                        <div className="download">
                            <a >
                                <ExportCSV csvData={users} fileName={"Registered Users"} />
                            </a>
                        </div>
                    
               
                    </div>

                </div>
              
            

        

               
              ) : (
                <div className="loginPage">
        
                    <button className="login" >Login</button>
                </div>
                        )}
        </div>
    )
}

export default Dashboard
